import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ArtifactPage from './components/ArtifactPage';

const App = () => {
  return (
      <Router>
        <Switch>
          <Route exact path="/details/:artifactIdentifier" component={ArtifactPage} />
        </Switch>
      </Router>
  );
};

export default App;