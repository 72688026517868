import React, {useState, useRef, useEffect, useCallback} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {Buffer} from "buffer";

import { Endpoints } from "./consts";

const PlayIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 5.14V19.14L19 12.14L8 5.14Z" fill="currentColor" />
    </svg>
);

const PauseIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 4H10V20H6V4ZM14 4H18V20H14V4Z" fill="currentColor" />
    </svg>
);

const ExpandIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 14L12 19L17 14H7Z" fill="currentColor" />
    </svg>
);

const OriginalLanguageIdentifier = "original";

// artifactIdentifier is an encoded string of "uu=${userId}&aa=${artifactId}"
const ArtifactPage = () => {
    const { artifactIdentifier } = useParams();
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(OriginalLanguageIdentifier);
    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [audioUrl, setAudioUrl] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
    const [artifactName, setArtifactName] = useState("");
    const [artifactText, setArtifactText] = useState(null);
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const decoded =  Buffer.from(artifactIdentifier, 'base64').toString('binary');
    console.log("decoded: ", decoded);
    const decodedIdentifier = decodeURIComponent(decoded);
    const params = new URLSearchParams(decodedIdentifier);
    const userId = params.get("uu");
    const artifactId = params.get("aa");

    const describeArtifactCallback = useCallback(async() => {
        const describedArtifact = await describeArtifact(userId, artifactId, currentLanguage);
        if (!describedArtifact) {
            alert(`failed to load details for this artifact}`);
        } else {
            setArtifactName(describedArtifact.name);
            setArtifactText(describedArtifact.artifactContentByLanguage);
            setAvailableLanguages(describedArtifact.supportedLanguages);

            const encodedAudioStorageKey = Buffer.from(describedArtifact.audioFileStorageKey, "binary").toString('base64');
            const audioUrl = `${process.env.REACT_APP_AUDIO_RETRIEVER_URL}/${encodedAudioStorageKey}`;
            setAudioUrl(audioUrl);
            if (audioRef.current) {
                audioRef.current.load();
            }
        }

    }, [userId, artifactId, currentLanguage]);

    useEffect(() => {
        describeArtifactCallback();
    }, [describeArtifactCallback]);

    const describeArtifact = async (userId, artifactId, selectedLanguage) => {
        const describeArtifactUrl = `${process.env.REACT_APP_API_URL}/${Endpoints.DescribeArtifact}/${userId}/${artifactId}/${selectedLanguage}`;
        try {
            const { data: response } = await axios.get(describeArtifactUrl);
            return response;
        } catch (err) {
            console.error("describeArtifact", err);
        }

        return null;
    }

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleLanguageChange = (e) => {
        setCurrentLanguage(e.target.value);
        setIsPlaying(false);
        setCurrentTime(0);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration);
    };

    const handleProgressChange = (e) => {
        const time = parseFloat(e.target.value);
        setCurrentTime(time);
        audioRef.current.currentTime = time;
    };

    return (
        <div
            className="flex flex-col items-center min-h-screen bg-gradient-to-b from-red-500 to-red-800 p-4 text-white">
            <select
                value={currentLanguage}
                onChange={handleLanguageChange}
                className="absolute top-2 right-2 p-2 rounded bg-white bg-opacity-20 text-white text-sm">
                {availableLanguages?.map((lang) => (
                    <option key={lang} value={lang}>
                        {lang}
                    </option>
                ))}
            </select>
            <div className="w-full max-w-md bg-white bg-opacity-10 rounded-xl p-6 backdrop-blur-lg relative">
                <div className="w-64 h-64 mx-auto mb-6 relative">
                    <img
                        src={`/api/album-cover`}
                        alt="album cover"
                        className="w-full h-full object-cover rounded-lg shadow-lg"/>
                    <button
                        onClick={togglePlay}
                        className="absolute inset-0 m-auto w-16 h-16 bg-red-500 bg-opacity-75 rounded-full flex items-center justify-center shadow-lg transition-transform transform hover:scale-105">
                        {isPlaying ? <PauseIcon/> : <PlayIcon/>}
                    </button>
                </div>

                <h2 className="text-2xl font-bold mb-2">{artifactName}</h2>
                <div className="mb-4">
                    <input
                        type="range"
                        min="0"
                        max={duration}
                        value={currentTime}
                        onChange={handleProgressChange}
                        className="w-full h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer"
                    />
                    <div className="flex justify-between text-sm text-gray-300 mt-1">
                        <span>{formatTime(currentTime)}</span>
                        <span>{formatTime(duration)}</span>
                    </div>
                </div>

                <div
                    className={`text-sm text-gray-300 mb-2 relative transition-all duration-300 ease-in-out cursor-pointer ${isExpanded ? 'h-auto' : 'h-24'} overflow-hidden`}
                    onClick={() => setIsExpanded(!isExpanded)}>
                    <p className="whitespace-pre-line">
                        {artifactText}
                    </p>
                    <div
                        className={`absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-red-800 to-transparent pointer-events-none transition-opacity duration-300 ${isExpanded ? 'opacity-0' : 'opacity-100'}`}></div>
                </div>

                <div
                    className="flex justify-center items-center h-8 text-gray-300 cursor-pointer hover:text-white transition-colors duration-200"
                    onClick={() => setIsExpanded(!isExpanded)}>
                    <ExpandIcon
                        className={`transform transition-transform duration-300 ${isExpanded ? 'rotate-180' : ''}`}/>
                </div>
            </div>

            <audio
                ref={audioRef}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
                loop>
                <source src={audioUrl} type="audio/mpeg"/>
            </audio>
        </div>
    );
};

export default ArtifactPage;